//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
import { formatDate, convertDuration } from '@/helpers/filters.js'
export default {
	mixins: [ImageSrcsetMixin],
	props: ['item_list_id', 'item_list_name', 'resources', 'model', 'direction', 'grid_type', 'content_type', 'slug', 'ratio', 'thumb_type'],
	data() {
		return {
			endReached: false
		};
	},
	components: {
		thumb: () => import('~/components/thumb'),
		VideoPreviewThumb: () => import('~/components/VideoPreviewThumb'),
	},
	computed: {
		...mapState({
			analytics: ({ analyticsStore: {analytics} }) => analytics
		}),
	},
	mounted() {
		this.$refs['slider_' + this.content_type].scrollLeft = 0;
	},
	methods: {
		convertDuration,
		sendAnalytics(resource) {
			var analyticsObj = {
				item_id: resource.id,
				item_name: resource.slug,
				currency: 'eur',
				item_brand: 'sexfilm', 
				item_variant: false,
				affiliation: false, 
				item_category: resource.recurring_subscription === 1 ? 'direct' : 'huren',
				item_list_id: this.item_list_id,
				item_list_name: this.item_list_name
			};
			if (!this.$route.query.orderStatusId) {
				this.$store.commit('analyticsStore/updateFullAnalyticsObject', analyticsObj);
				this.selectItem();
			}
		},
		scrollLeft(content_type) {
			const slider = this.$refs[`slider_${content_type}`];
			const item = this.$refs[`item_${content_type}_0`];
			const itemWidth = item[0].clientWidth;
			const screenWidth = window.innerWidth;
			const scrollAmount =
			Math.floor(slider.scrollLeft / itemWidth - screenWidth / itemWidth) *
			itemWidth;
			if (this.endReached) {
				slider.scrollTo({
					left: 0,
					behavior: 'smooth',
				});
				this.endReached = false;
			} else {
				slider.scrollTo({
					left: scrollAmount,
					behavior: 'smooth',
				});
			}
		},
		scrollRight(content_type) {
			const slider = this.$refs[`slider_${content_type}`];
			const item = this.$refs[`item_${content_type}_0`];
			const itemWidth = item[0].clientWidth;
			const screenWidth = window.innerWidth;
			const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
			const currentScrollLeft = slider.scrollLeft;
			let scrollAmount;
			if (currentScrollLeft + screenWidth >= slider.scrollWidth) {
				scrollAmount = 0;
			} else {
				scrollAmount = Math.round(currentScrollLeft / itemWidth + screenWidth / itemWidth) * itemWidth;
			}
			slider.scrollTo({
				left: scrollAmount,
				behavior: 'smooth',
			});
		},
		showScrollButtons(slider) {
			const arrowLeft = this.$refs[`arrowleft_${this.content_type}`];
			const arrowRight = this.$refs[`arrowright_${this.content_type}`];
			if (slider.scrollLeft === 0) {
				arrowLeft.style.display = 'none';
			} else {
				arrowLeft.style.display = 'flex';
			}
			// if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth && !this.endReached) {
			// 	arrowRight.style.display = 'none';
			// } else {
			// 	arrowRight.style.display = 'flex';
			// }
		},
		onScroll() {
			if (!this.isMobile) {
				this.$nextTick(() => {
					const slider = this.$refs[`slider_${this.content_type}`];
					this.showScrollButtons(slider);
				});
			}
		}
	}
}
