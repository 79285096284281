//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import dummyItem from '~/components/content/dummy/video_dummy.vue';

export default {
  async fetch() {
    await this.$store.dispatch('resourcesStore/getResources', this.queries.recommendedVideos)
  },
  components: {
    sectionTitle: () => import('~/components/section_title'),
    slider: () => import('~/components/slider'),
    dummyItem,
  },
  computed: {
    ...mapState({
      queries: ({ resourcesStore: {queries} }) => queries,
      recommendedVideos: ({ resourcesStore: {recommendedVideos} }) => recommendedVideos,
      loading: ({ resourcesStore: {loading} }) => loading
    }),
  },
};
