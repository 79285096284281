//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
import { formatDate, convertDuration } from '@/helpers/filters.js'
export default {
	mixins: [ImageSrcsetMixin],
	props: ['resource', 'ratio', 'slug', 'thumb', 'clip', 'minimal'],
	data() {
		return {
			hover: false,
			line_complete: false,
		};
	},
	methods: {
		formatDate, 
		convertDuration,
		taphandler() {
			if (this.resource.product === 'free') {
				this.$router.push('/gratis-sexfilms/' + this.resource.slug);
			} else {
				this.$router.push('/sexfilms/' + this.resource.slug);
			}
		},
		endHandler() {
			if (!this.line_complete) {
				this.cancelAllPreviews();
			}
		},
		hoverHandler(todo) {
			this.hover = todo;
		},
		touchHandler() {
			this.cancelAllPreviews();
			this.startPreview();
		},
		startPreview() {
			this.hover = true;
			setTimeout(() => {
				var element = document.getElementById('video_' + this.resource.id);
				if (element) {
					element.play();
					element.classList.add('active');
					this.line_complete = true;
				}
			}, 500);
		},
		cancelAllPreviews() {
			document.querySelectorAll('.preview').forEach((element) => {
				if (element && element.id != 'video_' + this.resource.id) {
					element.autoplay = false;
					element.load();
					element.classList.remove('active');
				}
			});
			document.querySelectorAll('.line_animation').forEach((element) => {
				if (element && element.id != 'line_' + this.resource.id) {
					element.classList.remove('active');
				}
			});
			this.hover = false;
			this.line_complete = false;
		},
	}
}
