//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
import { formatDate, convertDuration } from '@/helpers/filters.js'
export default {
	mixins: [ImageSrcsetMixin],
	props: ['resource', 'ratio', 'slug', 'thumb', 'minimal'],
	methods: {
		formatDate, 
		convertDuration
	}
}
