import { render, staticRenderFns } from "./slider.vue?vue&type=template&id=fd3ed22c"
import script from "./slider.vue?vue&type=script&lang=js"
export * from "./slider.vue?vue&type=script&lang=js"
import style0 from "./slider.vue?vue&type=style&index=0&id=fd3ed22c&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPreviewThumb: require('/home/forge/dev.meidenvanholland.nl/components/VideoPreviewThumb.vue').default,Thumb: require('/home/forge/dev.meidenvanholland.nl/components/thumb.vue').default})
